import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./LoginForm.css";
import logo from "../../Images/img_logo1.png";
import CarouselBar from "./CarouselBar";
import axios from "axios";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import CryptoJS from "crypto-js";
import doctor from "../../Images/doctorLogin.png";
//input
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const storageData = localStorage.getItem("userData");
    if (storageData != null) {
      navigate("/dashboard");
    }
  }, []);

  const [loding, setloding] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();

    setloding(true);
    axios
      .post("/login-with-saas", {
        email: email,
        password: password,
      })
      .then((res) => {
        localStorage.setItem(
          "ZGDIO4sPbQEa",
          JSON.stringify(res.data.access_token)
        );
        const userData = res.data.user;
        userData.branch_code = res.data.user.branch_id
          ? "BC-100" + res.data.user.branch_id
          : null;
        localStorage.setItem("userData", JSON.stringify(userData));
        navigate("/dashboard");
        setloding(false);
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
        setloding(false);
        if (err?.response?.status === 401) {
          swal("Alert !", err.response.data.message, "warning");
        } else {
          swal("Unauthorized !", "Invalid username or password", "error");
        }
      });
  };
  // input
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    // <section className="login-bg-img ">
    //   <div className="row mx-3 ">
    //     <div className="col-4 col-md-4  pt-3 border-end">
    //       <form onSubmit={handleSubmit} className="row  ms-3">
    //         <div className="form-group mt-5 pt-2 col-md-11 col-sm-11 mb-4 pt-0 text-center">
    //           <a href="/">
    //             <img
    //               src={logo}
    //               className=" img-fluid p-2"
    //               style={{ width: "300px" }}
    //               alt="SinewyCare"
    //               border="0"
    //             />
    //           </a>
    //         </div>

    //         <div className="form-group col-md-11 col-sm-11">
    //           <h5 className="fw-bolder text-center pt-4 text-login ">Login</h5>
    //         </div>

    //         <div className="form-group col-md-11 col-sm-11 mb-2">
    //           <div className="input-group pt-3">
    //             {/* <span className="input-group-text"><i className="far fa-envelope"></i></span> */}
    //             <input
    //               type="email"
    //               autoFocus
    //               name="email"
    //               className="form-control"
    //               placeholder="E-mail address"
    //               aria-label="email"
    //               value={email}
    //               onChange={(e) => setEmail(e.target.value)}
    //               required
    //             />
    //           </div>
    //         </div>
    //         <div className="form-group col-md-11 col-sm-11 pt-2">
    //           <div className="input-group ">
    //             {/* <span className="input-group-text"><i className="far fa-user"></i></span> */}
    //             <input
    //               type={`${passwordShow ? "text" : "password"}`}
    //               value={password}
    //               onChange={(e) => setPassword(e.target.value)}
    //               name="password"
    //               className="form-control"
    //               placeholder="Password"
    //               aria-label="password"
    //               required
    //             />

    //             <span
    //               className="input-group-text"
    //               style={{ height: "27px", cursor: "pointer" }}
    //             >
    //               {passwordShow ? (
    //                 <i
    //                   onClick={() => setpasswordShow(!passwordShow)}
    //                   class="fa-solid fa-eye-slash"
    //                 ></i>
    //               ) : (
    //                 <i
    //                   onClick={() => setpasswordShow(!passwordShow)}
    //                   class="fa-solid fa-eye"
    //                 ></i>
    //               )}
    //             </span>
    //           </div>
    //         </div>
    //         <div className="col-md-11 col-sm-11 pt-1">
    //           {/* <Link href="/user-signup"className=" text-decoration-none"><p className="forgot-password text-end"> User Sign Up </p></Link> */}
    //         </div>
    //         {/* <div className="col-md-11 col-sm-11 pt-2">
    //                         <div className="form-check">
    //                             <input className="form-check-input mt-2" type="checkbox" id="gridCheck" />
    //                             <label className="keepMeSiginIn" for="gridCheck">keep me sign in</label>
    //                         </div>
    //                     </div> */}
    //         <div className="col-md-11 col-sm-11 pt-3 ">
    //           {/* <a href="#"   className="text-decoration-none"> <input type="submit" style={{ backgroundColor: "#69B128" }} className="form-control  shadow rounded  fw-bolder text-white" value="Login" aria-label="login" required /></a> */}
    //           {/* <Link to ="/sidebar"   className="text-decoration-none"> <input type="submit" style={{ backgroundColor: "#69B128" }} className="form-control  shadow rounded  fw-bolder text-white" value="Login" aria-label="login" required /></Link> */}
    //           <input
    //             type="submit"
    //             style={{ backgroundColor: "#69B128" }}
    //             className="form-control  shadow rounded  fw-bolder text-white"
    //             value={`${loding ? "Request sending..." : "login"}`}
    //             aria-label="login"
    //             required
    //           />
    //         </div>
    //         {/* <div className="col-md-11 col-sm-11 pt-3">
    //                         <span className="text-center mb-0">Don't have a account yet? <a href="#" style={{ textDecoration: 'none' }} ><span className="text-register mb-0">Sign up</span></a></span>
    //                     </div> */}
    //       </form>
    //     </div>

    //     <div className="col-8 pt-5">
    //       <div className="container-md col-md-10 text-center">
    //         <h1 className="text-center pt-5">Clinical Management System</h1>
    //         <p className="text-muted fs-6 pt-1 ">
    //           SinewyCare helps to deliver superior healthcare for your doctors,
    //           clinics, diagnostics and pharmacy.
    //         </p>
    //         {/* <Link to={'/user-signup'} className="btn btn-sm m-2 btn-info"> User Sign Up </Link> */}
    //       </div>
    //       <div className="col-md-8 pt-5 offset-md-2">
    //         <CarouselBar></CarouselBar>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <div className="overflow-hidden">
      <div className="row">
        <div className="col-6">
          <img
            className="img-fluid "
            style={{ height: "100vh" }}
            src={doctor}
            alt=""
          />
        </div>
        <div className="col-6">
          <div className="mt-3">
            <form onSubmit={handleSubmit} className="row  ms-3">
              <div className="mt-5 pt-2 mb-4 pt-0 text-center">
                <a href="/">
                  <img
                    src={logo}
                    className=" img-fluid p-2"
                    style={{ width: "300px" }}
                    alt="SinewyCare"
                    border="0"
                  />
                </a>
              </div>
              {/* <h1 className="display-4 mt-3 fw-bold ">Welcome</h1> */}

              <h5 className="fw-bolder text-center pt-2 text-login ">
                Login with Email
              </h5>
              <div className=" d-flex justify-content-center">
                <FormControl
                  sx={{
                    m: 1,
                    width: "45ch",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "primary.main", // Default border color
                      },
                    },
                  }}
                  variant="outlined"
                >
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    htmlFor="outlined-adornment-password"
                  >
                    Email Id
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          aria-label={
                            showPassword
                              ? "hide the password"
                              : "display the password"
                          }
                          onClick={handleClickShowPassword}
                          edge="start"
                        >
                          <MailOutlineOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
              <div className="d-flex justify-content-center">
                <FormControl
                  sx={{
                    m: 1,
                    width: "45ch",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "primary.main", // Default border color
                      },
                    },
                  }}
                  variant="outlined"
                >
                  <InputLabel
                    sx={{
                      color: "primary.main",
                      "&.Mui-focused": {
                        color: "primary.main",
                      },
                    }}
                    htmlFor="outlined-adornment-password"
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <IconButton
                          aria-label={
                            showPassword
                              ? "hide the password"
                              : "display the password"
                          }
                          onClick={handleClickShowPassword}
                          edge="start"
                        >
                          {showPassword ? (
                            <LockOpenOutlinedIcon />
                          ) : (
                            <LockOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <div
                  style={{ width: "45ch" }}
                  className="d-flex justify-content-center "
                >
                  {/* <a href="#"   className="text-decoration-none"> <input type="submit" style={{ backgroundColor: "#69B128" }} className="form-control  shadow rounded  fw-bolder text-white" value="Login" aria-label="login" required /></a> */}
                  {/* <Link to ="/sidebar"   className="text-decoration-none"> <input type="submit" style={{ backgroundColor: "#69B128" }} className="form-control  shadow rounded  fw-bolder text-white" value="Login" aria-label="login" required /></Link> */}
                  <input
                    type="submit"
                    style={{
                      backgroundColor: "#69B128",
                      border: "none",
                    }}
                    className=" shadow rounded  text-white px-5 py-3"
                    value={`${loding ? "Loading..." : "LOGIN"}`}
                    aria-label="login"
                    required
                  />
                </div>
              </div>

              {/* <div className="col-md-11 col-sm-11 pt-3">
                            <span className="text-center mb-0">Don't have a account yet? <a href="#" style={{ textDecoration: 'none' }} ><span className="text-register mb-0">Sign up</span></a></span>
                        </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
